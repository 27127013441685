import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from 'element-ui';
import AMap from 'vue-amap';

import '@vuemap/vue-amap/dist/style.css'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(AMap);
Vue.use(Element);
Vue.config.productionTip = false;
import axios from 'axios'
Vue.prototype.$http= axios
window._AMapSecurityConfig = {
  securityJsCode: '6143e5d9b1af7525e15f5959ec831a85',
} 
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
