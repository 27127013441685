import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect:'/home'
  },
  {
    path: "/home",
    name: "home",
    component: ()=>import('@/views/home.vue'),
  },
  {
    path: "/news",
    name: "news",
    component: ()=>import('@/views/news.vue'),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: ()=>import('@/views/newsDetail.vue'),
  },
  {
    path: "/test",
    name: "test",
    component: ()=>import('@/views/test.vue'),
  },
  {
    path: "/AI",
    name: "AI",
    component: ()=>import('@/views/project/AI.vue'),
  },
  {
    path: "/kaohe",
    name: "kaohe",
    component: ()=>import('@/views/project/kaohe.vue'),
  },
  {
    path: "/shijian",
    name: "shijian",
    component: ()=>import('@/views/project/shijian.vue'),
  },
  {
    path: "/shouyun",
    name: "shouyun",
    component: ()=>import('@/views/project/shouyun.vue'),
  },
  {
    path: "/zhengfuhuanwei",
    name: "zhengfuhuanwei",
    component: ()=>import('@/views/project/zhengfuhuanwei.vue'),
  },
  {
    path: "/zhihuihuanwei",
    name: "zhihuihuanwei",
    component: ()=>import('@/views/project/zhihuihuanwei.vue'),
  },
  {
    path: "/sheshi",
    name: "sheshi",
    component: ()=>import('@/views/project/sheshi.vue'),
  },
  {
    path: "/about",
    name: "about",
    component: ()=>import('@/views/about.vue'),
  },
  {
    path: "/contact",
    name: "contact",
    component: ()=>import('@/views/contact.vue'),
  },
  {
    path: "/cheliangshebei",
    name: "cheliangshebei",
    component: ()=>import('@/views/project/cheliangshebei.vue'),
  },
  {
    path: "/wulianshebei",
    name: "wulianshebei",
    component: ()=>import('@/views/project/wulianshebei.vue'),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
